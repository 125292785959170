import React from "react";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

const items = [
  {
    image: "/images/future_items/marketplace-icon.png",
    title: "Full-Cycle Event Platform",
    width: "47%",
    content:
      "Plan, produce, fund, engage, and sell—all within one seamless ecosystem.",
  },
  {
    image: "/images/future_items/AI.png",
    title: "AI-Driven Insights",
    width: "47%",
    content: "Optimize event strategy with predictive analytics.",
  },
  {
    image: "/images/future_items/POS.png",
    title: "Integrated POS & Revenue Streams",
    width: "47%",
    content:
      " Monetize ticketing, funding, and merchandise while tapping into the local economy surrounding events. ",
  },
  {
    image: "/images/future_items/NFT-ticket.png",
    title: "Industry-Built Ecosystem",
    width: "47%",
    content:
      "Designed by industry leaders, connecting artists, venues, and promoters.",
  },
  {
    image: "/images/future_items/Launch.png",
    title: "Multichain Platform",
    width: "47%",
    content: "Supports multiple blockchains for flexibility and accessibility.",
  },
  {
    image: "/images/future_items/metaverse.png",
    title: "Effortless Web2 Mass Adoption",
    width: "47%",
    content: "No blockchain knowledge needed, with automatic wallet creation.",
  },
];

function Item(props) {
  const item = props.item;
  return (
    <div className={styles.item_wrapper} style={{ width: item.width }}>
      {/* <img
        className={styles.item_back}
        src="/images/future_items/item_back.png"
        alt=""
      /> */}
      <div className={styles.item}>
        <div className={styles.item_padding}>
          <img
            className={styles.item_image}
            src={item.image}
            alt={item.title}
          />
          <div className={styles.item_title}>{item.title}</div>
          <div className={styles.item_content}>{item.content}</div>
        </div>
      </div>
    </div>
  );
}

function FutureItems() {
  return (
    <div className={styles.items_box}>
      {items.map((item) => (
        <Item key={item.title} item={item} />
      ))}
    </div>
  );
}

function FutureEvents({ fRef }) {
  const { isMobile } = useGlobalStateContext();

  return (
    <div className={styles.future_wrapper}>
      <div className={styles.future}>
        <div className={styles.future_text} ref={fRef}>
          Revolutionizing Live {!isMobile && <br />} Experiences with Blockchain
        </div>
        <FutureItems />
      </div>
    </div>
  );
}

export default FutureEvents;
