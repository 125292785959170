/* eslint-disable array-callback-return */
import React from "react";
import styles from "./index.module.css";

function Gateway() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div style={{ marginTop: 104 }}>
          <p className={styles.title}>
            Your Gateway to a Secure & Fair Experience
          </p>
          <div className={styles.use_container}>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/user-plus.png"}
                alt=""
                style={{ width: 68, height: 60 }}
              />
              <p className={styles.use_text}>Create an Account</p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/purchase.png"}
                alt=""
                style={{ width: 68, height: 60 }}
              />
              <p className={styles.use_text}>Purchase with FIAT or Crypto</p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item} style={{ width: 222 }}>
              <img
                src={"/images/smartTicket/confirm.png"}
                alt=""
                style={{ width: 68, height: 68 }}
              />
              <p className={styles.use_text} style={{ marginTop: 20 }}>
                Receive Your Digital Item
              </p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/mint.png"}
                alt=""
                style={{ width: 68, height: 68 }}
              />
              <p className={styles.use_text} style={{ marginTop: 20 }}>
                Unlock Exclusive Perks
              </p>
            </div>
          </div>
          <div style={{ marginTop: 50 }}>
            <a href="https://backstage.global" className={styles.button}>
              Start Your Journey
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gateway;
