/* eslint-disable array-callback-return */
import React from "react";
import styles from "./index.module.css";

function Time() {
  const data = [
    {
      value: "$1.3T",
      text: "Events Market 2024",
      maxWidth: 262,
    },
    {
      value: "$94.9B",
      text: "Online ticketing Market by 2027",
      maxWidth: 200,
    },
    {
      value: "$10T",
      text: "Real World Asset Market by 2030",
      maxWidth: 250,
    },
    {
      value: "560M",
      text: "Crypto Users Today",
      maxWidth: 250,
    },
    {
      value: "$750M",
      text: "Active Fan Clubs Users",
      maxWidth: 250,
      sup: "/year",
    },
  ];

  // const uniques = [
  //   {
  //     img: "noUpfront",
  //     title: "RWAs Smart Tickets",
  //     text: "Secure, traceable, and resalable NFT-backed tickets.",
  //   },
  //   {
  //     img: "revenueShare",
  //     title: "Merchandise & Upselling",
  //     text: "Direct-to-fan sales, boosted by a network that taps into the event’s parallel economy.",
  //   },
  //   {
  //     img: "tailerMade",
  //     title: "Launchpad",
  //     text: "A crowdfunding platform connecting investors with event organizers and venues.",
  //   },
  //   {
  //     img: "userFriendly",
  //     title: "Backstage Rooms",
  //     text: "A hub for artists and creators to connect, engage, and monetize their fans.",
  //   },
  // ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>The Time Is Now</p>
        </div>
        <div className={styles.time_block}>
          <div className={styles.time_container}>
            {data.map((item, index) => (
              <div className={styles.item} key={index}>
                <p className={styles.item_value}>
                  {item.value}
                  <sup className={styles.sup}>{item.sup}</sup>
                </p>
                <p
                  className={styles.item_text}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.badge}>
            Capturing a portion of this Market will result in a large BKS Token
            Buyback
          </div>
        </div>
        {/* <div>
          <p className={styles.unique_title}>Marketplace</p>
        </div>
        <div className={styles.unique_block}>
          {uniques.map((item, index) => (
            <div key={index} className={styles.unique_item}>
              <div>
                <img
                  src={"/images/time/" + item.img + ".png"}
                  alt=""
                  className={styles.features_img}
                />
              </div>
              <p className={styles.unique_item_title}>{item.title}</p>
              <p className={styles.unique_item_text}>{item.text}</p>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Time;
