/* eslint-disable array-callback-return */
import React from "react";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

const advisors_data = [
  {
    img: "mads.jpg",
    name: "Mads Ulrick",
    desc: "CEO Backstage Global",
    link: "https://www.linkedin.com/in/mholmstrup",
  },
  {
    img: "mauricio.png",
    name: "Mauricio Silvestris",
    desc: "Founder & Chairmen at Backstage Global",
    link: "https://www.linkedin.com/in/mauricio-silvestris-1009031b",
  },
  {
    img: "mickey.jpg",
    name: "Mickey Lund",
    desc: "CFO at Backstage Global",
    link: "https://www.linkedin.com/in/mickey-lund-1129261",
  },
  {
    img: "stefano.jpg",
    name: "Stefano Mazzetti",
    desc: "COO at Backstage Global",
    link: "https://www.linkedin.com/in/stefano-mazzetti-a5a9b0211",
  },
  {
    img: "christian.jpg",
    name: "Cristian Dori",
    desc: "CCO at Backstage Global",
    link: "https://www.linkedin.com/in/christiandori",
  },
  {
    img: "Mike.jpg",
    name: "Mike Storm",
    desc: "Chief Operating Officer",
  },
  {
    img: "donato.jpg",
    name: "Donato Russo",
    desc: "Academic Blockchain and AI innovator-Web 3 Mentor",
  },
  {
    img: "Jarred.jpg",
    name: "Jarred Winn",
    desc: "Former Binance SVP, MarketingExecutive, & Blockchain Philanthropist",
  },
  {
    img: "Iaroslav.jpg",
    name: "Iaroslav Belkin",
    desc: "CEO & Founder at Belkin Marketing and Cryptopolitan | Ex Cointelegraph Head of Digital",
  },
  {
    img: "Rune.jpg",
    name: "Rune Matthiesen",
    desc: "Partner at Melitho Ventures | Crystal CBI",
  },
  {
    img: "Alessio.png",
    name: "Alessio Piras",
    desc: "BD at Backstage Global",
  },
  {
    img: "12.png",
    name: "Daniel Dasic",
    desc: "Global Business Development Professional & payments expert",
  },
  {
    img: "14.png",
    name: "Martin Bundgaard",
    desc: "Entertainment Industry expert",
  },
  {
    img: "andreas.jpg",
    name: "Andreas Jensen",
    desc: "CSM at Backstage Global",
  },
  {
    img: "16.png",
    name: "Simone Castaldi",
    desc: "Equity derivative trader and strategist broker",
  },
  {
    img: "3.png",
    name: "Immanuel Cape",
    desc: "Crypto & NFT expert",
  },
  {
    img: "4.png",
    name: "Kim Thurmann",
    desc: "Highly Experienced Event Marketer Partner of Rekom Group A/S",
  },
  {
    img: "5.png",
    name: "Nando Nicassio",
    desc: "Event Industry Entrepreneur",
  },
  {
    img: "7.png",
    name: "Naor Nurieli",
    desc: "DJ, Musician & Producer",
  },
  {
    img: "15.png",
    name: "Samer Naouri",
    desc: "Founder of Story Entertainment Internantional & Logistics Advisor",
  },
  {
    img: "6.png",
    name: "Francisco Lopez",
    desc: "Technology-Based Entrepreneurship expert",
  },
  {
    img: "2.png",
    name: "Shirly Valge",
    desc: "PM at Partisia Blockchain Foundation",
  },
  {
    img: "8.png",
    name: "Mattia Ulivieri",
    desc: "Event Industry Specialist & Managing Director at Blue Marlin Ibiza",
  },
  {
    img: "10.png",
    name: "Derek Sidney",
    desc: "USA Event Promoter & experienced Entrepreneur",
  },
  {
    img: "9.png",
    name: "Justin Brothers",
    desc: "Business Development Professional & experienced Entrepreneur ",
  },
  {
    img: "11.png",
    name: "Mike Scalera",
    desc: "Corporate Marketing Strategist",
  },
];
function Advisors({ isMobile }) {
  return (
    <div className={styles.advisors}>
      {advisors_data.map((advisor, index) => {
        // if (!isMobile || (advisor !== 37 && advisor !== 40))
        // if (advisor !== 33)
        return (
          <div className={styles.advisor} key={index}>
            {advisor?.link ? (
              <a href={advisor.link}>
                <img
                  src={"/images/advisors/" + advisor.img}
                  alt=""
                  className={styles.advisor_img}
                />
                <div className={styles.advisor_name_desc_wrapper}>
                  <div className={styles.advisor_name}>{advisor.name}</div>
                  <div className={styles.advisor_desc}>{advisor.desc}</div>
                </div>
              </a>
            ) : (
              <div>
                <img
                  src={"/images/advisors/" + advisor.img}
                  alt=""
                  className={styles.advisor_img}
                />
                <div className={styles.advisor_name_desc_wrapper}>
                  <div className={styles.advisor_name}>{advisor.name}</div>
                  <div className={styles.advisor_desc}>{advisor.desc}</div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function OurAdvisors() {
  const { isMobile } = useGlobalStateContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          A Team of Visionaries Driving Innovation
        </div>
        <p className={styles.description}>
          Backstage is backed by top blockchain specialists, music executives,
          and event industry leaders
        </p>
        <Advisors isMobile={isMobile} />
      </div>
    </div>
  );
}

export default OurAdvisors;
