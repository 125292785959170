/* eslint-disable array-callback-return */
import React from "react";
import styles from "./index.module.css";

function Currency() {
  const uniques = [
    {
      img: "noUpfront",
      title: "Borderless Payments",
      text: "Accept and process crypto transactions anywhere.",
    },
    {
      img: "revenueShare",
      title: "Instant Liquidity",
      text: "Marketplace transaction fees drive long-term growth through vested BKS tokens.",
    },
    {
      img: "tailerMade",
      title: "Payback Rewards",
      text: "Partners earn BKS tokens, boosting token utility and generating extra gains.",
    },
    {
      img: "userFriendly",
      title: "Smart Contract Revenue Sharing",
      text: "Automate artist and venue payouts for complete transparency.",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.unique_title}>
            The Currency of the Entertainment Economy
          </p>
        </div>
        <div className={styles.unique_block}>
          {uniques.map((item, index) => (
            <div key={index} className={styles.unique_item}>
              <p className={styles.unique_item_title}>{item.title}</p>
              <p className={styles.unique_item_text}>{item.text}</p>
            </div>
          ))}
        </div>
        {/* <div style={{ marginTop: 40 }}>
          <a
            href="https://bksbackstage.io/Backstage_WP.pdf"
            className={styles.bks_text}
          >
            Learn About BKS Token
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Currency;
