/* eslint-disable array-callback-return */
import React from "react";
import styles from "./index.module.css";

function Marketplace() {
  const uniques = [
    {
      img: "noUpfront",
      title: "RWAs Smart Tickets",
      text: "Secure, traceable, and resalable NFT-backed tickets.",
    },
    {
      img: "revenueShare",
      title: "Merchandise & Upselling",
      text: "Direct-to-fan sales, boosted by a network that taps into the event’s parallel economy.",
    },
    {
      img: "tailerMade",
      title: "Launchpad",
      text: "A crowdfunding platform connecting fans and investors with event organizers and venues.",
    },
    {
      img: "userFriendly",
      title: "Backstage Rooms",
      text: "A hub for artists and creators to connect, engage, and monetize their fans.",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.unique_title}>Marketplace</p>
          <p className={styles.sub_title}>
            The Premier Hub for Artists, Fans & Industry Leaders
          </p>
        </div>
        <div className={styles.unique_block}>
          {uniques.map((item, index) => (
            <div key={index} className={styles.unique_item}>
              <div>
                <img
                  src={"/images/time/" + item.img + ".png"}
                  alt=""
                  className={styles.features_img}
                />
              </div>
              <p className={styles.unique_item_title}>{item.title}</p>
              <p className={styles.unique_item_text}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Marketplace;
