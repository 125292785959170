import React, { useEffect, useRef } from "react";
import styles from "./index.module.css";

import Header from "../../../components/header";
import PurpleButton from "../../../components/PurpleButton";

function TopContent({ fRef }) {
  return (
    <div className={styles.top_content_wrapper}>
      <div className={styles.top_content}>
        <p className={styles.top_content_title}>
          Entertainment 3.0 – The Future of Events Powered by Blockchain
        </p>
        <p className={styles.top_content_text}>
          Backstage is at the forefront of the entertainment revolution,
          leveraging blockchain technology to create the only full-cycle
          Real-World Assets (RWA) EventFi Platform. In collaboration with
          world-renowned artists and industry leaders, we are redefining
          ticketing,engagement and empowering artists and fans.
        </p>
        <a href="https://backstage.global">
          <PurpleButton title="EXPLORE" route="" />
        </a>
      </div>
    </div>
  );
}

function TopBar({ fRef }) {
  const vRef = useRef();

  useEffect(() => {
    console.log("Page Loaded");
    vRef.current.play();
  }, []);

  useEffect(() => {
    console.log("Video Loaded");
    vRef.current.play();
  }, [vRef]);

  const onClickPage = () => {
    console.log("Page Clicked");
    vRef.current.play();
  };

  return (
    <div
      className={styles.top_bar}
      style={{
        backgroundImage: `url("/images/topbar.png")`,
        backgroundSize: "cover",
      }}
      onClick={onClickPage}
    >
      {/* <img
        className={styles.top_back}
        src="/images/top_back.png"
        alt="top_back"
      /> */}
      <div className={styles.top_video_wrapper}>
        <video
          autoPlay
          playsInline
          loop
          muted
          className={styles.top_video}
          ref={vRef}
        >
          <source src="/images/top_back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <img src="/images/topbar.png" alt="" /> */}
      </div>
      <Header />
      <TopContent fRef={fRef} />
    </div>
  );
}

export default TopBar;
