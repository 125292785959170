/* eslint-disable array-callback-return */
import React from "react";
// import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

function SmartTickets() {
  // const { isMobile } = useGlobalStateContext();
  const business_data = [
    {
      img: "Lower-fees",
      text: "Lower costs and higher profits through direct transactions.",
      maxWidth: 275,
    },
    {
      img: "Upsell",
      text: "Monetize the event-driven local economy.",
      maxWidth: 219,
    },
    {
      img: "protect",
      text: "Eliminate counterfeit tickets and monetize scalping.",
      maxWidth: 243,
    },
    {
      img: "revenue",
      text: "Unlock new revenue with exclusive offers.",
      maxWidth: 243,
    },
  ];
  const user_data = [
    {
      img: "Royalty",
      text: "Earn royalties from ticket resales.",
      maxWidth: 275,
    },
    {
      img: "Ticket",
      text: "Full ownership and transfer rights.",
      maxWidth: 230,
    },
    {
      img: "Collect",
      text: "Collect rare digital memorabilia.",
      maxWidth: 241,
    },
    {
      img: "Gain",
      text: "Enjoy better value with exclusive perks and engagement.",
      maxWidth: 243,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>Real-World Assets Ticket Experiences</p>
        </div>
        <div className={styles.benefit_block}>
          <div className={styles.benefit_title}>Business Benefits</div>
          <div className={styles.benefit_items}>
            {business_data.map((item, index) => (
              <div key={index} className={styles.item}>
                <div>
                  <img
                    src={"/images/smartTicket/" + item.img + ".png"}
                    alt=""
                    className={styles.item_img}
                  />
                </div>
                <p
                  className={styles.description}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.benefit_block}>
          <div className={styles.benefit_title}>Fan Benefits</div>
          <div className={styles.benefit_items}>
            {user_data.map((item, index) => (
              <div key={index} className={styles.item}>
                <div>
                  <img
                    src={"/images/smartTicket/" + item.img + ".png"}
                    alt=""
                  />
                </div>
                <p
                  className={styles.description}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartTickets;
